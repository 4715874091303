import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import firebase from 'firebase/compat/app';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {AngularFireFunctions} from '@angular/fire/compat/functions';
import {BehaviorSubject, Observable} from 'rxjs';
import {filter, map, take} from 'rxjs/operators';
import {DateTime} from 'luxon';
import {LoggingService} from './logging.service';
import {AngularFireStorage} from '@angular/fire/compat/storage';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private _redirectOnLogin: string | null;
    private _role: BehaviorSubject<string>;
    private _claims: BehaviorSubject<Record<string, any>>;

    constructor(
        private _fireAuth: AngularFireAuth,
        private _firestore: AngularFirestore,
        private _functions: AngularFireFunctions,
        private _fireStorage: AngularFireStorage,
        private _router: Router,
        private _loggingService: LoggingService,
    ) {
        this._redirectOnLogin = null;
        this._role = new BehaviorSubject<string>(null);
        this._claims = new BehaviorSubject<Record<string, any>>({});

        this._init();
    }

    setRedirectOnLogin(path: string): void {
        this._redirectOnLogin = path;
    }

    get redirectOnLogin(): string | null {
        return this._redirectOnLogin;
    }

    get role(): Observable<string> {
        return this._role.asObservable();
    }

    // Returns the authenticated user as an observable
    get user(): Observable<any> {
        try {
            return this._fireAuth.user;
        } catch (e) {
            this._loggingService.logError(e, '/src/app/services/auth.service.ts', 'user', 60);
        }
    }

    // Returns a boolean observable to indicate whether the current user has logged  or not
    get userLoggedOutPipe(): Observable<boolean> {
        try {
            return this._fireAuth.user.pipe(filter(user => user === null), map(value => true));
        } catch (e) {
            this._loggingService.logError(e, '/src/app/services/auth.service.ts', 'userLoggedOutPipe', 69);
        }
    }

    get claims(): Observable<any> {
        return this._claims.asObservable();
    }

    // Returns the current user from firebase auth
    currentUser(): Observable<firebase.User | null> {
        try {
            return this._fireAuth.authState;
        } catch (e) {
            this._loggingService.logError(e, '/src/app/services/auth.service.ts', 'currentUser', 78);
        }
    }

    async getUserClaims(): Promise<any> {
        try {
            const token = await (await this._fireAuth.currentUser).getIdTokenResult();
            return Promise.resolve(token.claims);
        } catch (e) {
            this._loggingService.logError(e, '/src/app/services/auth.service.ts', 'getUserClaims', 87);
            throw new Error('Getting User Claims Failed');
        }
    }

    async getUserDoc(id?: string): Promise<any> {
        try {
            const user = await this.user.pipe(take(1)).toPromise();
            const userId = id ? id : (user ? user.uid : null);
            if (!userId) {
                return null;
            }

            const userDoc = await this._firestore.collection('user').doc(userId).ref.get();

            return {...userDoc.data() as {}, id: userId};
        } catch (e) {
            this._loggingService.logError(e, '/src/app/services/auth.service.ts', 'getUserDoc', 91);
            throw new Error('Getting User Document Failed');
        }
    }

    async getUserDocByEmail(email: string): Promise<any> {
        try {
            const userQuery = await this._firestore.collection('user').ref.where('email', '==', email).get();
            return userQuery.docs.length > 0 ? {
                ...(userQuery.docs[0].data() as {}),
                id: userQuery.docs[0].id
            } : null;
        } catch (e) {
            this._loggingService.logError(e, '/src/app/services/auth.service.ts', 'getUserDocByEmail', 105);
            throw new Error('Getting User Document By Email Failed');
        }
    }

    async getUserAuthDoc(id?: string): Promise<any> {
        try {
            const user = await this.user.pipe(take(1)).toPromise();
            const userId = id ? id : (user ? user.uid : null);
            if (!userId) {
                return null;
            }

            const userDoc = await this._firestore.collection('userAuth').doc(userId).ref.get();

            return {...userDoc.data() as {}, id: userId};
        } catch (e) {
            this._loggingService.logError(e, '/src/app/services/auth.service.ts', 'getUserAuthDoc', 105);
            throw new Error('Getting User Auth Document Failed');
        }
    }

    async getUserSettingsDoc(id?: string): Promise<any> {
        try {
            const user = await this.user.pipe(take(1)).toPromise();
            const userId = id ? id : (user ? user.uid : null);
            if (!userId) {
                return null;
            }

            const userDoc = await this._firestore.collection('userSettings').doc(userId).ref.get();

            return {...userDoc.data() as {}, id: userId};
        } catch (e) {
            this._loggingService.logError(e, '/src/app/services/auth.service.ts', 'getUserSettingsDoc', 119);
            throw new Error('Getting User Settings Document Failed');
        }
    }

    async updateUserDocument(data: any): Promise<void> {
        try {
            const user = await this.user.pipe(take(1)).toPromise();
            const userId = user.uid;
            await this._firestore.collection('user').doc(userId).update(
                {
                    updated: DateTime.utc().toJSDate(),
                    updatedBy: userId,
                    ...data
                });
        } catch (e) {
            this._loggingService.logError(e, '/src/app/services/auth.service.ts', 'updateUserDocument', 135);
            throw new Error('Updating User Document Failed');
        }
    }

    async uploadResume(resume: any): Promise<any> {
        try {
            if (resume !== null && resume !== undefined) {
                const user = await this.user.pipe(take(1)).toPromise();
                const timestamp = DateTime.now().toUnixInteger().toString();
                const upload = await this._fireStorage.upload('resume/' +
                    user.uid + '/' + timestamp + '.pdf', resume);
                const ref = await upload.ref.getDownloadURL();
                return {
                    id: timestamp,
                    name: resume.name,
                    description: 'resume',
                    url: ref,
                    type: 'resume',
                    mimeType: resume.type,
                    extension: 'pdf',
                    size: resume.size,
                };
            }
            return Promise.reject('invalid-file');
        } catch (e) {
            this._loggingService.logError(e, '/src/app/services/auth.service.ts', 'uploadResume', 175);
            throw new Error('Uploading Resume Failed');
        }
    }

    async deleteResume(): Promise<any> {
        try {
            const user = await this.user.pipe(take(1)).toPromise();
            const data = {
                resume: null,
                updated: DateTime.utc().toJSDate(),
                updatedBy: user.uid
            };
            await this._firestore.collection('user').doc(user.uid).update(data);
            return data;
        } catch (e) {
            this._loggingService.logError(e, 'src/app/services/auth.service.ts',
                'deleteResume', 192);
        }
    }

    async updateUserSettingsDocument(data: any): Promise<void> {
        try {
            const user = await this.user.pipe(take(1)).toPromise();
            const userId = user.uid;
            await this._firestore.collection('userSettings').doc(userId).update(
                {
                    updated: DateTime.utc().toJSDate(),
                    updatedBy: userId,
                    ...data
                });
        } catch (e) {
            this._loggingService.logError(e, '/src/app/services/auth.service.ts', 'updateUserSettingsDocument', 151);
            throw new Error('Updating User Settings Document Failed');
        }
    }

    async login(email: string, password: string, remember: boolean = false): Promise<any> {
        try {
            // call the function to authenticate user
            await this._functions.httpsCallable('auth-authenticateUser')
            ({email: email, password: password, product: 'projects-portal'}).toPromise();
        } catch (e) {
            this._loggingService.logError(e, '/src/app/services/auth.service.ts', 'login', 162);
            if (e) {
                throw {code: e.message, message: 'Failed to authenticate user'};
            } else {
                throw {code: 'auth/login-failed', message: e.message};
            }
        }
        // Persist the user to the session storage after authentication
        try {
            if (remember) {
                await this._fireAuth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
            } else {
                await this._fireAuth.setPersistence(firebase.auth.Auth.Persistence.SESSION);
            }
        } catch (e) {
            this._loggingService.logError(e, '/src/app/services/auth.service.ts', 'login', 177);
        }

        try {
            // FireBase Auth Function to Sign user into the firebase
            await this._fireAuth.signInWithEmailAndPassword(email, password);
        } catch (err) {
            this._loggingService.logError(err, '/src/app/services/auth.service.ts', 'login', 184);
            if (err) {
                throw err;
            } else {
                throw {code: 'auth/login-failed', message: 'Login Failed'};
            }
        }

        return Promise.resolve(true);
    }

    async register(user: any, userType: string): Promise<any> {
        try {
            let organizationId;
            // Create organization if it doesn't exist
            if (!user.organization.ref) {
                const domainSplitArray = user.email.toLowerCase().trim().split('@')[1].split('.');
                let domain = domainSplitArray[domainSplitArray.length - 2] + '.' + domainSplitArray[domainSplitArray.length - 1];
                // For domains that end with co.uk, co.aus, co.in, etc.
                if (domainSplitArray.length >= 3 && domainSplitArray[domainSplitArray.length - 2] === 'co') {
                    domain = domainSplitArray[domainSplitArray.length - 3] + '.' + domainSplitArray[domainSplitArray.length - 2]
                        + '.' + domainSplitArray[domainSplitArray.length - 1];
                }
                const organizationObj = {
                    name: user.organization.trim(),
                    type: userType.trim().toLowerCase() === 'employer' ? 'employer' : 'university',
                    product: 'projects-portal',
                    products: [{
                        product: 'projects-portal',
                        activated: true,
                        trial: false
                    }],
                    timezone: '-05:00',
                    domains: [domain],
                    activated: true
                };
                await this._functions.httpsCallable('organization-createOrganization')(organizationObj).toPromise();

                const organizationQuery = await this._firestore.collection('organization').ref
                    .where('name', '==', organizationObj.name)
                    .where('domains', '==', organizationObj.domains)
                    .where('activated', '==', organizationObj.activated)
                    .get();

                if (organizationQuery.size === 1) {
                    organizationId = organizationQuery.docs[0].id;
                }

            }
            else {
                organizationId = user.organization.id;
            }

            const userData = {
                firstName: user.firstName.trim(),
                lastName: user.lastName.trim(),
                email: user.email.toLowerCase().trim(),
                role: userType.trim().toLowerCase(),
                organizationId: organizationId,
                createMethod: 'projectsPortal'
            };

            if (user.password) {
                userData['password'] = user.password.trim();
            }

            // Create user account
            const response = await this._functions.httpsCallable('auth-register')(userData).toPromise();

            // Save project idea for the user
            if (user.projectIdea) {
                await this._firestore.collection('projectIdea').doc(response.uid).set({
                    created: DateTime.utc().toJSDate(),
                    createdBy: response.uid,
                    updated: DateTime.utc().toJSDate(),
                    updatedBy: response.uid,
                    id: response.uid,
                    firstName: user.firstName.trim(),
                    lastName: user.lastName.trim(),
                    email: user.email.toLowerCase().trim(),
                    projectIdea: user.projectIdea
                });
            }

            return Promise.resolve('User Successfully Created');

        } catch (e) {
            this._loggingService.logError(e, '/src/app/services/auth.service.ts', 'register', 245);
            throw {code: e.message, message: 'User Registration Failed'};
        }
    }

    async sendEmailVerification(email: string, adminName?: string): Promise<any> {
        try {
            return await this._functions.httpsCallable('auth-sendVerificationEmail')(
                {
                    email: email,
                    adminName: adminName,
                    type: 'projectsPortal'
                }).toPromise();
        } catch (e) {
            this._loggingService.logError(e, '/src/app/services/auth.service.ts', 'sendEmailVerification', 258);
            throw {code: e.message, message: 'Sending verification email failed'};
        }
    }

    async verifyEmailVerificationToken(token: string): Promise<any> {
        let email;
        try {
            email = await this._functions.httpsCallable('auth-verifyEmailVerificationToken')({token: token}).toPromise();
        } catch (e) {
            this._loggingService.logError(e, '/src/app/services/auth.service.ts',
                'verifyEmailVerificationToken', 346);
            throw {code: e.message, message: 'Email verification failed'};
        }
        try {
            const providers = await this._fireAuth.fetchSignInMethodsForEmail(email);
            // Redirect users without a password to create password component with token
            if (providers.length === 0) {
                return '/auth/create-password?mode=createPassword&token=' + token;
            }
        } catch (e) {
            this._loggingService.logError(e, '/src/app/services/auth.service.ts',
                'verifyEmailVerificationToken', 357);
            throw {code: e.message, message: 'Email verification failed'};
        }
        return '/auth?login';
    }

    async resetPassword(token: string | null | undefined, password: string): Promise<any> {
        try {
            return await this._functions.httpsCallable('auth-resetPassword')(
                {
                    token: token,
                    password: password
                }
            ).toPromise();
        } catch (e) {
            this._loggingService.logError(e, '/src/app/services/auth.service.ts', 'resetPassword', 282);
            throw {code: e.message, message: 'Resetting password failed'};
        }
    }

    async sendPasswordResetEmail(email: string): Promise<any> {
        try {
            return await this._functions.httpsCallable('auth-sendPasswordResetEmail')({
                email: email,
                type: 'projectsPortal'
            }).toPromise();
        } catch (e) {
            this._loggingService.logError(e, '/src/app/services/auth.service.ts', 'sendPasswordResetEmail', 294);
            throw {code: e.message, message: 'Sending password reset email failed'};
        }
    }

    async sendSupportEmail(params: object): Promise<any> {
        try {
            return await this._functions.httpsCallable('support-createTicket')(params).toPromise();
        } catch (e) {
            this._loggingService.logError(e, '/src/app/services/auth.service.ts', 'sendSupportEmail', 303);
            throw {code: e.message, message: 'Creating support ticket failed'};
        }
    }

    async token(): Promise<firebase.auth.IdTokenResult> {
        try {
            return await (await this._fireAuth.currentUser).getIdTokenResult();
        } catch (e) {
            this._loggingService.logError(e, '/src/app/services/auth.service.ts', 'token', 312);
            throw e;
        }
    }

    async getJWT(): Promise<string> {
        try {
            return await this._functions.httpsCallable('auth-getElasticGuardToken')({}).toPromise();
        } catch (e) {
            this._loggingService.logError(e, '/src/app/services/auth.service.ts', 'getJWT', 321);
            throw {code: e.message, message: 'Getting Elastic Guard token failed'};
        }
    }

    async verifyCaptcha(token: string): Promise<any> {
        try {
            return await this._functions.httpsCallable('auth-verifyCaptcha')({
                token: token
            }).toPromise();
        } catch (e) {
            this._loggingService.logError(e, '/src/app/services/auth.service.ts', 'verifyCaptcha', 331);
            throw {code: e.message, message: 'Google Recaptcha verification failed'};
        }
    }

    async logout(): Promise<void> {
        try {
            await this._fireAuth.signOut();
            // redirect to the homepage
            window.location.replace('');
        } catch (e) {
            this._loggingService.logError(e, '/src/app/services/auth.service.ts', 'logout', 342);
        }
        return Promise.resolve();
    }

    async uploadProfilePicture(profilePicture: any): Promise<string> {
        try {
            const currentUser = await this.currentUser().pipe(take(1)).toPromise();
            const upload = await this._fireStorage.upload('profilePic/' + currentUser.uid, profilePicture);
            const ref = await upload.ref.getDownloadURL();

            return ref as string;
        } catch (e) {
            this._loggingService.logError(e, '/src/app/services/auth.service.ts',
                'uploadProfilePicture', 352);
        }
    }

    async getSignInCustomToken(): Promise<any> {
        try {
            const user = await this.user.pipe(take(1)).toPromise();
            return await this._functions.httpsCallable('auth-generateSignInCustomToken')({ uid: user.uid }).toPromise();
        } catch (e) {
            this._loggingService.logError(e, '/src/app/services/auth.service.ts', 'getSignInCustomToken', 476);
        }
    }

    async ssoLogin(token: string): Promise<any> {
        try {
            await this._fireAuth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
            await this._fireAuth.signInWithCustomToken(token);
        } catch (e) {
            this._loggingService.logError(e, '/src/app/services/auth.service.ts', 'ssoLogin', 490);
            throw new Error(e);
        }
    }

    // Save product switch event in firestore for analytics
    async saveProductSwitchEvent(destination: string): Promise<void> {
        try {
            const user = await this.getUserDoc();
            const role = await this.role.pipe(take(1)).toPromise();

            await this._firestore.collection('productSwitchEvent')
                .add({
                    created: DateTime.utc().toJSDate(),
                    userId: user.id,
                    email: user['email'],
                    role: role,
                    organizationRef: user['organizationRef'],
                    sourceProduct: 'student-projects',
                    destinationProduct: destination
                });
        } catch (e) {
            this._loggingService.logError(e, '/src/app/services/auth.service.ts', 'saveProductSwitchEvent', 5140);
        }
    }

    _init(): void {
        this.user
            .subscribe(async user => {
                if (user) {
                    await this._firestore.collection('userAuth').doc(user.uid).valueChanges()
                        .subscribe((data) => {
                            this._role.next(data['role']);
                        });
                } else {
                    this._role.next(null);
                }
            });
        this._fireAuth.authState.subscribe(async user => {
            if (user) {
                const token = await user.getIdTokenResult();
                this._claims.next(token.claims);
            } else {
                this._claims.next({});
            }
        });
    }
}
